import React, { Component } from 'react';

// css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


// router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// pages
import HomePage from '../pages/home/Home';
import ListPage from '../pages/list/List';
import AddPage from '../pages/add/Add';
import EditPage from '../pages/edit/Edit';




class App extends Component {
  render() {
    return (
    
    
    
    <Router>
      <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link to={'/'} className="navbar-brand">Home</Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={'/list'} className="nav-link">List</Link>
            </li>
            <li className="nav-item">
              <Link to={'/add'} className="nav-link">Add</Link>
            </li>
            <li className="nav-item">
              <Link to={'/edit'} className="nav-link">Edit</Link>
            </li>
            </ul>
          </div>
        </nav>
        
        <Switch>
          <Route exact path={'/'} component={ HomePage }/>
          <Route path='/list' component={ ListPage } />
          <Route path='/add' component={ AddPage } />
          <Route path='/edit' component={ EditPage } />
        </Switch>

      </div>
      
    </Router>
    
    
    );
  }
}

export default App;
