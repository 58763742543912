import React, { Component } from 'react';




class Add extends Component {


  constructor(props) {
    super(props);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeGsm = this.onChangeGsm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      gsm: "",
    }
  }

  onChangeFirstName(e){
    this.setState({
      firstName : e.target.value
    });
  }
  onChangeLastName(e){
    this.setState({
      lastName : e.target.value
    });
  }
  onChangeGsm(e){
    this.setState({
      gsm : e.target.value
    });
  }

  onSubmit(e){
    e.preventDefault();
    var messageText = "Person values are " + this.state.firstName + ", "+this.state.lastName+", and " + this.state.gsm;
    console.log(messageText);
    alert(messageText);

    this.setState({
      firstName: "",
      lastName: "",
      gsm: "",
    });
  }



  render() {
    return (
      <div style={{ marginTop: 10 }}>
        <h3>Add New Person</h3>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>Person Firstname:  </label>
            <input type="text" value={this.state.firstName} onChange={this.onChangeFirstName} className="form-control" />
          </div>
          <div className="form-group">
            <label>Person Lastname: </label>
            <input type="text" value={this.state.lastName} onChange={this.onChangeLastName} className="form-control" />
          </div>
          <div className="form-group">
            <label>Person GSM: </label>
            <input type="text" value={this.state.gsm} onChange={this.onChangeGsm} className="form-control" />
          </div>
          
          <div className="form-group">
            <input type="submit" value="Save" className="btn btn-primary" />
            {/* <input type="button" value="Change First Name" onClick={ this.changeFirstName } className="btn btn-success" />
            <input type="button" value="Change Last Name" onClick={ this.changeLastName } className="btn btn-success" />
            <input type="button" value="Change GSM" onClick={ this.changeGsm } className="btn btn-success" /> */}
          </div>
        </form>
      </div>
    );
  }
}

export default Add;
