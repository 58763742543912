import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// pages
import App from './app/App';





ReactDOM.render(<App />, document.getElementById('root'));

